import React from "react"
import { Link } from "gatsby"
import styled from "@emotion/styled"
import theme from "./theme"

import {
  background,
  borderRadius,
  border,
  color,
  flexbox,
  colorStyle,
  fontFamily,
  fontSize,
  fontStyle,
  fontWeight,
  grid,
  layout,
  letterSpacing,
  lineHeight,
  position,
  space,
  size,
  textStyle,
  typography,
} from "styled-system"

const {
  brevier,
  siteName,
  doublePica,
  pica,
  bodyCopy,
  paragon,
} = theme.textStyles

export const Box = styled.div`
  ${background}
  ${borderRadius}
  ${border}
  ${color}
  ${colorStyle}
  ${flexbox}
  ${fontFamily}
  ${fontSize}
  ${fontStyle}
  ${fontWeight}
  ${fontWeight}
  ${grid}
  ${letterSpacing}
  ${layout}
  ${lineHeight}
  ${position}
  ${size}
  ${space}
  ${textStyle}
  ${typography}
`

const Text = styled.p`
  ${background}
  ${color}
  ${fontFamily}
  ${fontSize}
  ${fontWeight}
  ${layout}
  ${letterSpacing}
  ${lineHeight}
  ${position}
  ${space}
  ${typography}
  margin: 0;
  text-transform: ${props => (props.uppercase ? "uppercase" : "")};
  cursor: ${props => props.pointer};
`

// Text

export const SiteName = props => (
  <Text {...siteName} {...props} uppercase={true}>
    {props.children}
  </Text>
)

export const Paragon = props => (
  <Text {...paragon} {...props} uppercase={true}>
    {props.children}
  </Text>
)

export const DoublePica = props => (
  <Text {...doublePica} {...props}>
    {props.children}
  </Text>
)

export const Pica = props => (
  <Text {...pica} {...props}>
    {props.children}
  </Text>
)

export const BodyCopy = props => (
  <Text {...bodyCopy} {...props}>
    {props.children}
  </Text>
)

export const BodyCopyCapitalized = styled(BodyCopy)`
  &::first-letter {
    font-size: 42px;
    font-weight: 100;
  }
`

export const Brevier = props => (
  <Text {...brevier} {...props} uppercase={true} letterSpacing="2px">
    {props.children}
  </Text>
)

export const Bullet = props => {
  return (
    <Box textAlign="center" display="flex" alignItems="center" {...props}>
      <Box
        display="inline-block"
        backgroundColor={props.bulletColor || theme.colors.neonGreen}
        height="10px"
        width="10px"
        marginRight={2}
        borderRadius="50%"
        border={props.bulletBorder}
      />
      {props.children}
    </Box>
  )
}

export const PageLink = styled(props => <Link {...props} />)`
  text-decoration: none;
  box-shadow: none;
  cursor: pointer;
  color: ${theme.colors.darkGray};
`

const Dot = styled(Box)`
  height: 20px;
  width: 20px;
  margin-right: 3px;
  display: inline-block;
  border-radius: 50%;
`

const Line = styled(Box)`
  height: 2px;
  margin-left: 3px;
  flex-grow: 1;
`

export const BulletLine = props => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      pt={[0, 1]}
      pl={[4, 5]}
    >
      <Dot backgroundColor={props.color} />
      <Box mx={2}>
        <Brevier>{props.text}</Brevier>
      </Box>
      <Line backgroundColor={props.color} />
    </Box>
  )
}

const breakpoints = ["479px", "719px", "999px", "1199"]

breakpoints.sm = breakpoints[0]
breakpoints.md = breakpoints[1]
breakpoints.lg = breakpoints[2]
breakpoints.xl = breakpoints[3]

const mediaQueries = breakpoints.map(bp => `@media (min-width: ${bp}px)`)

const spaces = [0, 4, 8, 16, 32, 64, 128, 256, 512]

const sizes = [16, 32, 64, 128, 256, 512, 600, 720]

const fontSizes = [8, 10, 12, 14, 18, 24, 32, 36, 48, 60, 72, 84, 100]

const maxWidth = [600, 780, 1300]

const mapHeight = [400, 550]

const responsiveFontSizes = {
  small: [0, 1, 2],
  medium: [2, 3, 5],
  large: [4, 6, 7],
}

const colors = {
  black: "#000000",
  darkGray: "#565656",
  gray: "#c2c2c2",
  lightGray: "#909090",
  lighterGray: "#D7D7D7",
  orange: "#FFC166",
  lightOrange: "#FEEFD8",
  lighterOrange: "#FFFBF4",
  neonGreen: "#D7FF62",
  lightGreen: "#FCFFF2",
  green: "#d4d7cd",
  blue: "#093FFF",
  yellow: "#f0eac6",
  white: "#FFFFFF",
}

const fontFamilies = {
  sourceSerifPro: {
    name: "Source Serif Pro",
    weights: [400, 600, 700],
  },
  robotoMono: {
    name: "Roboto Mono",
    weights: [100, 400, 700],
  },
  cinzel: {
    name: "Cinzel",
    weights: [700, 900],
  },
}

const textStyles = {
  siteName: {
    fontFamily: fontFamilies.robotoMono.name,
    fontWeight: 900,
    color: colors.black,
    fontSize: [6, 7],
    letterSpacing: "1px",
    lineHeight: ["48px", "60px"],
  },
  paragon: {
    fontFamily: fontFamilies.cinzel.name,
    fontWeight: 900,
    color: colors.darkGray,
    fontSize: [4],
  },
  doublePica: {
    fontFamily: fontFamilies.sourceSerifPro.name,
    fontWeight: 400,
    color: colors.black,
    fontSize: [4, 5],
  },
  pica: {
    fontFamily: fontFamilies.sourceSerifPro.name,
    fontWeight: 400,
    color: colors.black,
    fontSize: [2, 3, 4],
  },
  bodyCopy: {
    fontFamily: fontFamilies.robotoMono.name,
    fontWeight: 400,
    color: colors.black,
    fontSize: [1, 2],
  },
  brevier: {
    fontFamily: fontFamilies.sourceSerifPro.name,
    fontWeight: 400,
    color: colors.darkGray,
    fontSize: [1, 2],
    lineHeight: ["36px", "40px"],
  },
}

const theme = {
  breakpoints,
  colors,
  fontSizes,
  fontFamilies,
  mapHeight,
  maxWidth,
  mediaQueries,
  responsiveFontSizes,
  spaces,
  sizes,
  textStyles,
}

export default theme

import React, { useState, useContext } from "react"
import styled from "@emotion/styled"
import { ParallaxProvider } from "react-scroll-parallax"

import theme from "../utils/theme"
import { Box, Brevier, Paragon, PageLink } from "../utils/styledComponents"
import NavContext from "../utils/navContext"

const HeaderLink = styled.span`
  cursor: pointer;
`

// const BrevierWithHover = styled(Brevier)`
//   &:hover {
//     color: ${props => props.color || props.theme.colors.black};
//   }
// `

const NavTop = props => {
  return (
    <React.Fragment>
      {`${props.currentPageName}  `}
      <span>&#9662;</span>
    </React.Fragment>
  )
}

const Header = props => {
  const { headerTextColor } = props
  const [showNav, setShowNav] = useState(false)
  const navConsumer = useContext(NavContext)
  const { navLinks, currentPage } = navConsumer

  const navBottomLinks =
    navLinks && currentPage
      ? navLinks.filter(link => link.seoName !== currentPage)
      : []

  return (
    <Box
      py={[3]}
      px={[3, 4]}
      display="flex"
      alignItems={showNav ? "flex-start" : "center"}
      justifyContent="space-between"
      backgroundColor={showNav ? "lightGreen" : "unset"}
    >
      <Box>
        <PageLink to="/">
          <Paragon
            color={showNav ? "darkGray" : headerTextColor}
            fontSize={[5, 7]}
          >
            N
          </Paragon>
        </PageLink>
      </Box>
      <Box display="flex" flexDirection="column" alignItems="flex-end">
        <Box>
          <HeaderLink onClick={() => setShowNav(!showNav)}>
            {currentPage && (
              <Brevier color={showNav ? "darkGray" : headerTextColor}>
                <NavTop currentPageName={currentPage} />
              </Brevier>
            )}
          </HeaderLink>
        </Box>
        <Box display={showNav ? "unset" : "none"} mr="15px">
          {navBottomLinks.map((link, index) => {
            return (
              <PageLink key={`page_link_${index}`} to={`/${link.slug}`}>
                <Brevier>{link.seoName}</Brevier>
              </PageLink>
            )
          })}
        </Box>
      </Box>
    </Box>
  )
}

const BaseLayout = props => {
  return (
    < ParallaxProvider>
      <Box
        width="100%"
        minHeight="100vh"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        backgroundColor={props.backgroundColor || theme.colors.lighterGray}
        {...props}
      >
        {!props.hideHeader && (
          <Header
            currentPageName={props.currentPageName}
            navLinks={props.navLinks}
            headerTextColor={props.headerTextColor}
          />
        )}
        {props.children}
        <Box
          display="flex"
          width="100%"
          justifyContent="center"
          py={[4]}
          px={[3]}
          backgroundColor={props.footerBackgroundColor}
        >
          <Brevier fontSize={[1]} color={props.footerTextColor || "darkGray"}>
            Nibbles.Guide | all rights reserved
          </Brevier>
        </Box>
      </Box>
    </ ParallaxProvider>
  )
}

export const getNavLinks = (cities, pages) => {
  return [...cities, ...pages]
}

// TODO: make base fragment

export default BaseLayout
